@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.text-pricing-table h3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #7ea037;
    text-align: center;
}

.text-pricing-table h2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    text-align: center;
    color: #170f49;
}

.text-pricing-table p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #6f6c90;
    padding: 20px 0px 20px 0;
    margin: 0px;
}

.text-pricing-table {
    width: 640px;
    margin: auto;
    padding: 40px 0 0 0;
}

section.pricing-sect {
    background: #e5e5e5;
}

section.pricing-columns.pricing-section {
    background: #e5e5e5;
    padding-bottom: 90px;
}

section.slider-logo {
    background: #e5e5e5;
    padding-bottom: 50px;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.toggle,
.toggler {
    display: inline-block;
    vertical-align: middle;
    margin: 10px;
}

.toggler {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #170f49;
}

.toggler--is-active {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #170f49;
}

.b {
    display: block;
}

.toggle {
    position: relative;
    width: 80px;
    height: 35px;
    border-radius: 100px;
    background-color: #34a853;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
}

.check:checked ~ .switch {
    right: 2px;
    left: 57.5%;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0.08s, 0s;
}

.switch {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 57.5%;
    background-color: #fff;
    border-radius: 36px;
    z-index: 1;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, 0.08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.pricing-section {
    padding: 10px 0 50px 0;
    float: left;
    width: 100%;
    text-align: center;
}

.pricing-columns .wrapper-full {
    position: relative;
    width: 1170px;
    margin: 0 auto;
}

.pricing-columns #pricing-chart,
.pricing-columns-both #pricing-chart {
    margin-top: 100px;
    float: left;
    position: relative;
}

.pricing-columns #pricing-chart #smaller-plans,
.pricing-columns-both #pricing-chart #smaller-plans {
    display: flex;
    grid-gap: 20px;
}

.pricing-columns #pricing-chart .plan .price,
.pricing-columns-both #pricing-chart .plan .price {
    font-size: 26px;
    float: left;
    width: 100%;
    margin-bottom: 35px;
    margin-top: 17px;
}

.pricing-columns #pricing-chart .plan .price .dollar,
.pricing-columns-both #pricing-chart .plan .price .dollar {
    top: -10px;
    letter-spacing: -1px;
    left: 3px;
}

.pricing-columns #pricing-chart .plan .price .amount,
.pricing-columns-both #pricing-chart .plan .price .amount {
    font-size: 30px;
    font-weight: 600;
}

.pricing-columns #pricing-chart .plan .price .slash,
.pricing-columns-both #pricing-chart .plan .price .slash {
    font-weight: 600;
    left: -2px;
}

.pricing-columns #pricing-chart .plan .price .month,
.pricing-columns-both #pricing-chart .plan .price .month {
    font-weight: 600;
    left: -7px;
}

.pricing-columns #pricing-chart .plan ul,
.pricing-columns-both #pricing-chart .plan ul {
    margin-bottom: 40px;
}

.pricing-columns #pricing-chart .plan ul li,
.pricing-columns-both #pricing-chart .plan ul li {
    text-align: left;
    margin-bottom: 20px;
}

.icluded-div li img {
    padding: 0 14px 0 0;
}

.icluded-div ul li {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #170f49;
}

.pricing-columns #pricing-chart .plan ul li span,
.pricing-columns-both #pricing-chart .plan ul li span {
    color: #9e9e9e;
    display: inline-block;
    margin-left: 7px;
    font-weight: 400;
    font-size: 14px;
}

.pricing-columns #pricing-chart .plan a.sign-up,
.pricing-columns-both #pricing-chart .plan a.sign-up {
    width: 100%;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.pricing-columns #pricing-chart .popular,
.pricing-columns-both #pricing-chart .popular {
    width: 274px;
    position: relative;
    top: -30px;
    background-color: white;
    margin-left: -2px;
    right: 0;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
}

.icluded-div a.button.sign-up {
    background: #34a853;
    border-radius: 7px;
    padding: 20px 80px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    text-decoration: unset;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    border: 1px solid;
    transition: .9s !important;
}

.pricing-columns #pricing-chart .popular:before,
.pricing-columns-both #pricing-chart .popular:before {
    width: 274px;
    background-color: #9d1726;
    height: 7px;
}

.pricing-columns #pricing-chart .popular a.sign-up,
.pricing-columns-both #pricing-chart .popular a.sign-up {
    background-color: #9d1726;
    border: none;
    color: #ffffff;
}

.pricing-columns #pricing-chart .popular .price .amount,
.pricing-columns-both #pricing-chart .popular .price .amount {
    font-size: 60px;
}

.pricing-columns #pricing-chart .popular p.pop-plan,
.pricing-columns-both #pricing-chart .popular p.pop-plan {
    color: #454545;
    font-size: 16px;
    margin-bottom: 45px;
}

.pricing-columns #pricing-chart .popular .price,
.pricing-columns-both #pricing-chart .popular .price {
    margin-bottom: 0px;
}

.pricing-columns #pricing-chart .plan a.sign-up:hover,
.pricing-columns-both #pricing-chart .plan a.sign-up:hover {
    border: 0;
    color: #fff;
    opacity: 0.8;
}

.hide {
    display: none;
}

.du0-flex p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #6f6c90;
    margin-bottom: 3px;
}

.price-div {
    display: flex;
    align-items: center;
    grid-gap: 30px;
}

.du0-flex span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #170f49;
}

.du0-flex {
    text-align: left;
}

.description-table p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #6f6c90;
    margin-top: 20px;
}

.monthly-rate span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 66px;
    color: #170f49;
}

.monthly-rate {
    text-align: left;
}

.monthly-rate p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #6f6c90;
}

.icluded-div p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #170f49;
}

.icluded-div {
    text-align: left;
}

.icluded-div a.button.sign-up:hover {
    background: white;
    color: #34a853 !important;
    border: 1px solid #34a853 !important;
    transition: .9s !important;
}

div#smaller-plans .plan {
    padding: 40px;
    background: #ffffff;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 12px rgb(20 20 43 / 8%);
    border-radius: 10px;
}

div#smaller-plans .plan:nth-child(2) {
    background: #7ea037 !important;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 12px rgb(20 20 43 / 8%);
    border-radius: 10px;
    margin-top: -45px;
    height: 660px;
}

div#smaller-plans .plan:nth-child(2) p {
    color: white;
}

div#smaller-plans .plan:nth-child(2) span {
    color: white;
}

div#smaller-plans .plan:nth-child(2) ul li {
    color: white;
}

div#smaller-plans .plan:nth-child(2) a.button.sign-up {
    background: white;
    color: #34a853;
}

.text-slider h3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
}

.logo-site img {
    height: 50px;
    width: auto !important;
}

.title-testimonial h2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #7ea037;
}

.title-testimonial h2:before {
    content: "";
    position: absolute;
    top: 9px;
    border: 2px solid #7ea037;
    width: 37px;
    left: 0px;
}

.title-testimonial h2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #7ea037;
    position: relative;
    padding-left: 45px;
}

.test-slider p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #6f6c90;
}

.text-09 span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #6f6c90;
}

.title-testimonial p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    color: #170f49;
}

.test-slider h3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #170f49;
}

.test-sliderp {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #6f6c90;
}

.test-slider h3 span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #6f6c90;
}

.d-flex {
    align-items: center;
}

.text-09 h3 {
    margin-bottom: 0;
}

section.testimonial-slider .item {
    background: #ffffff;
    border: 1px solid #eff0f7;
    box-shadow: 0px 5px 14px rgb(8 15 52 / 4%);
    border-radius: 20px;
    padding: 40px;
}

section.testimonial-slider {
    padding: 80px 0;
    position: relative;
}

section.testimonial-slider .owl-nav.disabled {
    display: block !important;
}

section.testimonial-slider .owl-nav.disabled button:hover {
    background: #34a853;
}

section.testimonial-slider .owl-nav.disabled button {
    background: #34a853;
    color: white;
    font-size: 40px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: relative;
}

section.testimonial-slider .owl-nav.disabled button span {
    position: absolute;
    top: -20px;
    left: 8px;
}

section.testimonial-slider .owl-nav.disabled button:focus {
    outline: none;
}

section.testimonial-slider .owl-nav.disabled button.owl-prev {
    position: absolute !important;
    top: 50%;
    left: -70px;
}

section.testimonial-slider .owl-nav.disabled button.owl-next {
    position: absolute !important;
    right: -70px;
    top: 50%;
}

.title-faq {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 62px;
    text-align: center;
    color: #170f49;
    margin-bottom: 40px;
}

.accordian-compagin-98 {
    background: #ffffff;
    box-shadow: 0px 5px 16px rgb(8 15 52 / 6%);
    border-radius: 16px;
}

section.accordion-faqs {
    background: #e5e5e5;
    padding: 60px 0 80px 0;
}

.image-text-question-84 h3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #170f49;
    margin-bottom: 0px;
    cursor: pointer;
}

.image-text-question-84 p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #6f6c90;
    margin-top: 10px;
}

.image-text-question-84 {
    padding: 30px;
    margin: 12px 0 12px 0;
}

ul.accordion-list h3:after {
    content: "\002B";
    font-size: 15px;
    color: #34a853;
    float: right;
    background: #F7F7FF;
    padding: 0px 6px 6px 7px;
    width: 32px;
    height: 32px;
    font-size: 30px;
    border-radius: 8px;
}

ul.accordion-list li.active h3:after {
    content: "\2212";
    font-size: 30px;
    color: #fff;
    float: right;
    background: #34a853;
    padding: 0px 6px 6px 7px;
    width: 32px;
    height: 32px;
    font-size: 30px;
    border-radius: 8px;
}

@-webkit-keyframes L_circle_rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes L_circle_rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes L_stroke_rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }

    100% {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes L_stroke_rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }

    100% {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@-webkit-keyframes L_stroke_left_grow {

    0%, 100% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    50% {
        -webkit-transform: rotate(-140deg);
        transform: rotate(-140deg);
    }
}

@keyframes L_stroke_left_grow {

    0%, 100% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    50% {
        -webkit-transform: rotate(-140deg);
        transform: rotate(-140deg);
    }
}

@-webkit-keyframes L_stroke_right_grow {

    0%, 100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    50% {
        -webkit-transform: rotate(140deg);
        transform: rotate(140deg);
    }
}

@keyframes L_stroke_right_grow {

    0%, 100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    50% {
        -webkit-transform: rotate(140deg);
        transform: rotate(140deg);
    }
}

.loader-circle {
    top: 50%;
    left: 50%;
    z-index: 1;
    color: #444;
    margin-top: -1em;
    margin-left: -1em;
    position: absolute;
    -webkit-animation: L_circle_rotate 1.568s linear infinite both;
    animation: L_circle_rotate 1.568s linear infinite both;
}

.loader-circle .loader-stroke-left:before,
.loader-circle .loader-stroke-right:before,
.loader-circle:before {
    content: "";
    display: block;
    border-style: solid;
    border-width: 0.21429em;
    border-color: currentColor;
}

.loader-circle,
.loader-circle .loader-stroke-left,
.loader-circle .loader-stroke-left:before,
.loader-circle .loader-stroke-right,
.loader-circle .loader-stroke-right:before,
.loader-circle:before {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    box-sizing: border-box;
}

.loader-circle .loader-stroke-left:before,
.loader-circle .loader-stroke-right {
    position: absolute;
    clip: rect(0 2em 2em 1em);
}

.loader-circle .loader-stroke-left,
.loader-circle .loader-stroke-right:before {
    position: absolute;
    clip: rect(0 1em 2em 0);
}

.loader-circle:before {
    position: absolute;
    clip: rect(0 1.05em 1em 0.95em);
}

.loader-circle .loader-stroke-left,
.loader-circle .loader-stroke-right,
.loader-circle:before {
    -webkit-animation: L_stroke_rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: L_stroke_rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.loader-circle .loader-stroke-right:before {
    -webkit-animation: L_stroke_right_grow 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: L_stroke_right_grow 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.loader-circle .loader-stroke-left:before {
    -webkit-animation: L_stroke_left_grow 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: L_stroke_left_grow 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mhn-slide .mhn-item {
    width: 100%;
    padding: 10px;
}

.mhn-slide .mhn-inner {
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 3px;
}

.mhn-slide .mhn-item img {
    display: none;
}

.mhn-slide .mhn-img {
    min-height: 200px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    color: white;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) #eee no-repeat center/cover;
    position: relative;
}

.mhn-slide .mhn-text {
    text-align: center;
    padding: 0 10px;
}

.mhn-slide .mhn-text h4 {
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mhn-slide .mhn-text p {
    max-height: 4.5em;
    overflow: hidden;
}

.mhn-slide .owl-stage-outer {
    z-index: 1;
}

.mhn-slide .owl-nav {
    color: #333;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.mhn-slide .owl-nav svg {
    color: currentColor;
}

.mhn-slide .owl-nav .disabled {
    display: none;
}

.mhn-slide .owl-prev,
.mhn-slide .owl-next {
    top: 110px;
    z-index: 2;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-top: -20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3), 0 0 4px rgba(0, 0, 0, 0.2);
}

section.te-slider button svg {
    fill: white;
    background: #34a853;
    border-radius: 50px;
}

.test-slider {
    background: #ffffff;
    border: 1px solid #eff0f7;
    border-radius: 20px;
    padding: 40px;
    margin-right: 30px;
}

.mhn-slide .owl-prev {
    left: -10px;
}

.mhn-slide .owl-next {
    right: -10px;
}

.mhn-slide .owl-prev,
.mhn-slide .owl-next {
    top: 195px !important;
}

.mhn-slide .owl-next {
    right: 100px;
}

.mhn-slide .owl-prev {
    left: 50px;
}

section.te-slider {
    padding: 80px 0;
}

.img-text {
    padding-right: 12px;
}

section.te-slider button svg:focus {
    outline: none;
}

section.te-slider button:focus {
    outline: none;
}

.logo-site img {
    margin: auto;
}

section.signup-page-sec-65 {
    background: #E5E5E5;
}

.signup-main-div-56 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.signup-password-div-icon-985 {
    background: #FFFFFF;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0196802), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0282725), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.035), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0417275), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0503198), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    width: 468px;
    padding: 75px;
}

.signup-logo-div {
    text-align: center;
    padding-bottom: 38px;
}

.signup-input-field-div-633 h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #173D51;
    text-align: center;
    margin-bottom: 14px;
    margin-top: 0;
    text-transform: uppercase;
}

.signup-input-field-div-633 .input-field {
    margin-bottom: 14px;
}

.signup-input-field-div-633 input {
    width: 100%;
    border: 1px solid #847C7C;
    border-radius: 5px;
    height: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #000;
    padding: 0 17px;
}

.signup-input-field-div-633 input::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #847C7C;
}

a.btn-sign-up-654,
button.btn-sign-up-654 {
    border: none;
    background: #34A853;
    border-radius: 7px;
    height: 50px;
    margin-bottom: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: unset;
}

.signup-input-field-div-633 p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #847C7C;
    text-align: center;
}

.signup-input-field-div-633 p span a {
    color: #34a853;
}

.signup-or-div-96 p {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #847C7C;
    position: relative;
    margin: 25px 0 10px;
}

.signup-or-div-96 p::after {
    position: absolute;
    content: '';
    width: 120px;
    border-top: 1px solid #847C7C;
    right: 22px;
    top: 9px;
}

.signup-or-div-96 p::before {
    position: absolute;
    content: '';
    width: 120px;
    border-top: 1px solid #847C7C;
    left: 22px;
    top: 9px;
}

.signup-icon-image-div84 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-icon-image-div84 a {
    border: 1px solid #847C7C;
    border-radius: 5px;
    width: 75px;
    height: 50px;
    display: block;
    text-align: center;
    padding-top: 11px;
}


.signup-icon-image-div84 a:nth-child(2) {
    margin: 0 14px;
}

.signup-dont-have-div-654 p {
    margin-top: 35px;
    text-align: center;
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #847C7C;
}

.signup-dont-have-div-654 p span a {
    color: #34a853;
    font-weight: bold;
}


.signup-dont-have-div-655 p {
    margin-top: 15px;
    text-align: center;
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #847C7C;
}

.signup-dont-have-div-655 p span a {
    color: #34a853;
    font-weight: bold;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
    div#smaller-plans {
        display: block !important;
    }

    .plan {
        margin-bottom: 70px;
    }

    div#smaller-plans .plan:nth-child(2) {
        height: auto;
    }

    .text-pricing-table {
        width: auto;
    }

    div#monthly {
        width: auto;
    }

    div#hourly {
        width: auto;
    }

    section.accordion-faqs .row {
        display: block;
    }

    section.accordion-faqs .row .col.\31 2.col-lg-6.col-md-12.col-sm-12 {
        background: #e5e5e5;
    }

    .logo-site img {
        margin: auto;
    }

    .test-slider {
        margin: auto;
    }

    .mhn-slide .owl-next {
        right: 20px;
    }

    .mhn-slide .owl-prev {
        left: 20px;
    }

    div#smaller-plans .plan:nth-child(2) {
        margin-top: 0px;
    }

    .image-text-question-84 p {
        font-size: 15px;
        max-width: 300px;
    }

    .image-text-question-84 h3 {
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    div#smaller-plans .plan:nth-child(2) {
        margin-top: 40px;
    }

    .plan {
        margin-bottom: 70px;
    }

    div#smaller-plans {
        display: block !important;
    }

    .logo-site img {
        margin: auto;
    }
}

.google-signup-hint {
    font-size: .875em;
    margin-bottom: 15px;
}

.error-messages {
    width: 100%;
    margin-top: 0.25rem;
    text-align: center;
    color: #dc3545;
}
